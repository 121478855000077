/* 
EN: This file is generated automatically, don't edit manually. Please modify app-dependant.json if necessary and then restart app, this file will be updated automatically.
VI: File này được sinh tự động, đừng sửa trực tiếp vào đây, nếu cần thì hãy sửa file app-dependant.json sau đó chạy lại app, file này sẽ được tự động update theo.
*/

export class AppConst {
  public static readonly Version: string = "0.0.64";
  public static readonly BuildWhen: string = "2024-11-04 10:41:41";
  public static readonly devFeatureBranch: string = "";
  public static readonly devFeatureBuildBy: string = "";
  public static readonly gaTrackingId: string = "";
  public static readonly devFeature: string = "";
}
